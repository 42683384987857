<template>
    <div>
        <div class="modal fade" tabindex="-1" :id="modalId" data-bs-backdrop="static" data-bs-keyboard="false">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content" v-if="visible">

                    <div class="modal-body">
                        <p class="text-center">
                            <img :src="gravatar" alt="Avatar" class="img-thumbnail" style="border-radius: 50%;">
                            <br>
                            <span class="lead">
                                {{(user.firstName) ? `${user.firstName} ${user.lastName}` : "Ne s'est pas encore connecté"}}
                                <i class="bi bi-stars text-warning" v-if="userProtected"></i>
                            </span>
                            <br>
                            <small><em>{{user.email}}</em></small>
                        </p>
                        
                        <div class="row">
                            <div class="col">
                                <div class="mb-3">
                                    <label for="" class="form-label"><h6>Groupe</h6></label>
                                    <select name="" id="" class="form-select form-select-sm" v-model="userGroup._id" required :disabled="disabled">
                                        <option v-for="group in groups" :key="group._id" :value="group._id">{{group.name}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col">
                                <div class="mb-3">
                                    <label for="" class="form-label"><h6>Profil</h6></label>
                                    <select name="" id="" class="form-select form-select-sm" v-model="localUser.quota_id" required :disabled="disabled">
                                        <option v-for="quota in quotas" :key="quota._id" :value="quota">{{quota.name}}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div class="mb-3">
                                    <label for="" class="form-label"><h6>Crédit horaire restant</h6></label>
                                    <input type="number" name="" id="" class="form-control form-control-sm" :max="userLimits.totalHours*userLimits.totalInstances" min="0" v-model="userLimits.freeHours" :disabled="disabled">
                                </div>
                            </div>
                            <div class="col">
                                <div class="mb-3">
                                    <label for="" class="form-label"><h6>Etat</h6></label>
                                    <select name="" id="" class="form-select form-select-sm" v-model="localUser.isActive" required :disabled="userProtected || disabled">
                                        <option value="true">Actif</option>
                                        <option value="false">Suspendu</option>
                                    </select>                                
                                </div>
                            </div>
                        </div>

                        
                        <div class="mb-3">
                            <button class="btn btn-secondary w-100" :disabled="disabled" @click="saveModifications()"><i class="bi bi-save me-2"></i>Enregistrer les modifications</button>
                        </div>

                        <label for="" class="form-label"><h6>Stats</h6></label>
                        <div class="card shodow shadow-sm bg-light">
                            <div class="card-body">
                                <div class="mb-3">
                                    <label for="" class="form-label">Infra(s) <small class="text-muted"><em>{{localUser.maxInfras-userLimits.freeInfras}}/{{localUser.maxInfras}}</em></small></label>
                                    <div class="progress" style="height:5px;">
                                        <div class="progress-bar" :class="{'bg-warning': ( (( (localUser.maxInfras-userLimits.freeInfras) /localUser.maxInfras)*100 ) >= 100) }"  role="progressbar" :style="'width: '+( (( (localUser.maxInfras-userLimits.freeInfras) /localUser.maxInfras)*100 ) >= 100)+'%'"></div>
                                    </div>
                                </div>
                                <div class="mb-3">
                                    <label for="" class="form-label">Instance(s) <small class="text-muted"><em>{{userLimits.totalInstances - userLimits.freeInstances}}/{{userLimits.totalInstances}}</em></small></label>
                                    <div class="progress" style="height:5px;">
                                        <div class="progress-bar" :class="{'bg-warning': ( ((userLimits.totalInstances - userLimits.freeInstances) / userLimits.totalInstances)*100 ) >= 100}"  role="progressbar" :style="'width: '+( ((userLimits.totalInstances - userLimits.freeInstances) / userLimits.totalInstances)*100 )+'%'"></div>
                                    </div>
                                </div>
                                <div class="mb-3">
                                    <label for="" class="form-label">Stockage <small class="text-muted"><em>{{userLimits.totalStorage - userLimits.freeStorage}}/{{userLimits.totalStorage}}</em></small></label>
                                    <div class="progress" style="height:5px;">
                                        <div class="progress-bar" :class="{'bg-warning': ( ((userLimits.totalStorage - userLimits.freeStorage) / userLimits.totalStorage)*100 ) >= 100}"  role="progressbar" :style="'width: '+( ((userLimits.totalStorage - userLimits.freeStorage) / userLimits.totalStorage)*100 )+'%'"></div>
                                    </div>
                                </div>
                                <div class="mb-3">
                                    <label for="" class="form-label">Heure(s) <small class="text-muted"><em>{{ ( (userLimits.totalHours*userLimits.totalInstances) - userLimits.freeHours ).toFixed(2) }}/{{userLimits.totalHours*userLimits.totalInstances}}</em></small></label>
                                    <div class="progress" style="height:5px;">
                                        <div class="progress-bar" :class="{'bg-warning': ( ((userLimits.totalHours*userLimits.totalInstances) - userLimits.freeHours) / (userLimits.totalHours*userLimits.totalInstances) )*100 >= 100}"  role="progressbar" :style="'width: '+(((userLimits.totalHours*userLimits.totalInstances) - userLimits.freeHours) / (userLimits.totalHours*userLimits.totalInstances))*100+'%'"></div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>


                    <div class="modal-footer">
                        <button type="button" class="btn btn-sm" @click.prevent="close()" :disabled="disabled">
                            <i class="bi bi-x me-2"></i>Fermer
                        </button>
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>

    import { defineProps, defineEmits, onMounted, computed, ref, toRef } from 'vue';
    import Modal from "bootstrap/js/dist/modal";
    import axios from "axios";
    import crypto from "crypto";

    let props = defineProps(["modalId","show","user","group","limits","quotas","groups","userProtected"]);
    let emits = defineEmits(["close","saved"]);
    let disabled = ref(false);

    // Compute avatar user
    let gravatar = computed( function() {
        if(props.user.email){
            return `https://www.gravatar.com/avatar/${crypto.createHash('md5').update(props.user.email).digest("hex")}`;
        }
        else{
            return `https://www.gravatar.com/avatar/${crypto.createHash('md5').update("gravatar@formacloud.ltd").digest("hex")}`;
        }
    })

    let localUser = toRef(props, "user");
    let userGroup = toRef(props, "group");
    let userLimits = toRef(props, "limits");

    let visible = computed( () => {
        if(props.show){
            let myModal = Modal.getOrCreateInstance(document.querySelector('#' + props.modalId));
            myModal.show();
        }
        else{
            try{
                let myModal = Modal.getOrCreateInstance(document.querySelector('#' + props.modalId));
                myModal.hide();
            }
            catch(e){
                return props.show;
            }
        }
        return props.show;
    });



    onMounted( () => {
        
    })

    function close(){
        emits("close");
    }

    function saveModifications(){
        disabled.value = true;
        axios.put(`/api/users/${localUser.value._id}`, {
            quota_id: localUser.value.quota_id._id,
            runningHours: ( (userLimits.value.totalHours*userLimits.value.totalInstances) - userLimits.value.freeHours),
            isActive: localUser.value.isActive
        })
        .then( answer => {
            return axios.post(`/api/groups/${userGroup.value._id}/import`, {emails: [localUser.value.email]})
        })
        .then( answer => {
            disabled.value = false;
            emits("saved");
        })
        .catch( answer => {
            alert( (answer.response.data.message) ? answer.response.data.message : answer );
        })
    }

</script>