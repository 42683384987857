<template>
    <div class="modal fade" tabindex="-1" :id="modalId" data-bs-backdrop="static" data-bs-keyboard="false">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content" v-if="visible">
                <div class="modal-header">
                    <h6 class="modal-title">Nouvel utilisateur</h6>
                    <button type="button" class="btn-close" @click.prevent="close()" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="mb-3">
                        <label for="" class="form-label">E-mail</label>
                        <input type="email" class="form-control form-control-sm" v-model="userEmail" />
                    </div>
                    <div class="mb-3">
                        <label for="" class="form-label">Mot de passe</label>
                        <input type="email" class="form-control form-control-sm" disabled />
                    </div>
                    <div class="mb-3">
                        <label for="" class="form-label">Confirmer</label>
                        <input type="email" class="form-control form-control-sm" disabled />
                    </div>
                    <div class="mb-3">
                        <label for="" class="form-label">Type d'utilisateur</label>
                        <select name="" id="" class="form-select form-select-sm" v-model="userLevel" required :disabled="userInfo.authLevel > 0">
                            <option value="2" selected="selected">User</option>
                            <option value="1" v-if="userInfo.authLevel === 0">Modérateur</option>
                        </select>
                    </div>
                    <div class="mb-3">
                        <label for="" class="form-label">Profil de quota</label>
                        <select name="" id="" class="form-select form-select-sm" v-model="userQuota" required>
                            <option v-for="quota in quotas" :key="quota._id" :value="quota._id">{{quota.name}}</option>
                        </select>
                    </div>
                    <div class="mb-3">
                        <label for="" class="form-label">Activer le compte par défaut</label>
                        <select name="" id="" class="form-select form-select-sm" v-model="userActive" required>
                            <option value="false" selected="selected">Non</option>
                            <option value="true">Oui</option>
                        </select>
                    </div>
                </div>
                
                <div class="modal-footer">
                    <button type="button" class="btn btn-sm" @click.prevent="close()" :disabled="disabled || userQuota === ''"><i class="bi bi-x me-2"></i>Annuler</button>
                    <button type="button" class="btn btn-sm" :disabled="disabled || userQuota === ''" @click.prevent="addUser()">
                        <i class="bi bi-check me-2"></i>Ajouter
                    </button>
                </div>
            </div>
        </div>
        <input type="hidden" name="" v-model="defaultQuota">
    </div>
</template>

<script setup>

    import { defineProps, defineEmits, onMounted, ref, computed } from 'vue';
    import Modal from "bootstrap/js/dist/modal";
    import axios from "axios";

    let props = defineProps(["modalId","show","userInfo","quotas"]);
    let emits = defineEmits(["close","error","success"]);

    let visible = computed( () => {
        if(props.show){
            let myModal = Modal.getOrCreateInstance(document.querySelector('#' + props.modalId));
            myModal.show();
        }
        else{
            try{
                let myModal = Modal.getOrCreateInstance(document.querySelector('#' + props.modalId));
                myModal.hide();
            }
            catch(e){
                return props.show;
            }
        }
        return props.show;
    });

    let disabled = ref(false);
    let userEmail = ref("");
    let userLevel = ref(2);
    let userActive = ref(0);
    let userQuota = ref("");

    let defaultQuota = computed(()=>{
        return (props.quotas.length > 0) ? props.quotas.filter( q => q.name === "Standard")[0]._id : "";
    });


    onMounted( () => {

    });

    function close(){
        userEmail.value = "";
        emits("close");
    }

    function addUser(){
        disabled.value = true;
        axios.post("/api/users", {
            email: userEmail.value,
            authLevel: userLevel.value,
            quota_id: userQuota.value,
            isActive: userActive.value
        })
            .then( answer => {
                disabled.value = false;
                emits("success");
                close();
            })
            .catch( answer => {
                disabled.value = false;
                emits("error", (answer.response.data.message) ? answer.response.data.message : answer);
            });
    }

    function isDefaultQuota(quotaName){
        return quotaName === "Standard";
    }

</script>