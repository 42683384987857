<template>  
    <div>
        <!-- Header of the page -->
        <h4 class="mb-3 align-middle">
            Utilisateurs
            <div class="btn-group float-end" role="group" aria-label="Basic example">
                <button class="btn" @click="showUserAdd = true"><i class="bi bi-person-plus me-1"></i> Ajouter</button>
                <button class="btn" @click="showImportUsers = true"><i class="bi bi-person-plus me-1"></i> Imp. </button>
                <button class="btn" v-if="userInfo.authLevel === 0" @click="showImportModerators = true"><i class="bi bi-person-plus me-1"></i> Imp. modérateurs</button>
                <button class="btn" :disabled="selectedUsers.length === 0" @click="showConfirmEnable = true"><i class="bi bi-shield-check me-1"></i> Status</button>
                <button class="btn" v-if="selectedUsers.length === 0" @click="selectedUsers = users.filter( u => u.authLevel > 0 ).map( u => u._id)"><i class="bi bi-check-square me-1"></i> Tous/Aucun</button>
                <button class="btn" v-if="selectedUsers.length > 0" @click="selectedUsers = []"><i class="bi bi-square me-1"></i> Tous/Aucun</button>
                <button class="btn text-danger" :disabled="selectedUsers.length === 0" @click="showConfirm = true"><i class="bi bi-trash me-1"></i> Supprimer</button>
            </div>
        </h4>

        <p style="text-align: justify;">
            Vous pouvez ajouter/importer des utilisateurs ainsi que modifier leur profil et quotas individuellement.
            En actions de groupe, vous pouvez Activer, Désactiver, Supprimer des comptes utilisateurs.
        </p>
        <p style="text-align: justify;">
            Vous pouvez filtrer les utilisateurs en recherchant des valeurs ou bien choisir un groupe.
        </p>
        
        
        <div class="row">
            <div class="col">
                <div class="mb-3">
                    <label for="" class="form-label"><h6>Filtre</h6></label>
                    <input type="text" name="" id="" class="form-control mb-3" placeholder="Filtrer..." v-model="searchUser">
                </div>
            </div>
            <div class="col">
                <div class="mb-3">
                    <label for="" class="form-label"><h6>Groupe</h6></label>
                    <select name="" id="" class="form-select" v-model="filterGroup">
                        <option value="0">-- Tous --</option>
                        <option v-for="group in groups" :key="group._id" :value="group">{{group.name}}</option>
                    </select>
                </div>
            </div>
        </div>

        <!-- List of users -->
        <div class="mb-3">
            <label for="" class="form-label"><h6>Résultat(s)</h6></label>
            <ul class="list-group" v-if="loaded && filteredUsers.length > 0">
                <li class="list-group-item list-group-item-action" v-for="user in filteredUsers" :key="user._id">
                    <table>
                        <tbody>
                            <tr>
                                <td>
                                    <input  role="button" class="form-check-input me-2" type="checkbox" v-if="user.authLevel > 0" :value="user._id" v-model="selectedUsers" aria-label="...">
                                    <input class="form-check-input me-2" type="checkbox" v-else disabled/>
                                </td>
                                <td>
                                    <i class="bi bi-person-circle me-2" :class="{'text-danger': !user.isActive, 'text-success': user.isActive}"></i>
                                </td>
                                <td class="w-100">
                                    {{user.lastName}}
                                    {{user.firstName}}
                                    [{{user.email}}]
                                    <!-- <i class="bi bi-star-fill ms-2 me-2 text-warning" v-if="user.authLevel <= 1" alt="Moderator or admin"></i> -->
                                    <!-- <i class="bi bi-bookmark-star-fill ms-2 me-2 text-warning" v-if="user.authLevel <= 1" alt="Moderator or admin"></i> -->
                                    <i class="bi bi-hash ms-2 me-2 text-primary" v-if="user.authLevel <= 1" alt="Moderator or admin"></i>
                                    <em class="text-muted"><small>{{ (user.authLevel===0)?"(protected)":"" }}</small></em>
                                </td>
                                <td>
                                    <!-- Display the time remaining for this month on the infra -->
                                    <div class="progress me-2" style="width: 100px;">
                                        <div class="progress-bar progress-bar-striped progress-bar-animated" :style="'width: '+ ( user.runningHours / (user.quota_id.maxHours*user.quota_id.maxInstances) )*100+'%;'" role="progressbar" :aria-valuenow="( user.runningHours / (user.quota_id.maxHours*user.quota_id.maxInstances) )*100" aria-valuemin="0" aria-valuemax="100"></div>
                                    </div>
                                </td>
                                <td @click="describeUserClicked(user)" class="text-end" role="button" title="Afficher les détails">
                                    <i class="bi bi-arrows-angle-expand me-2"></i>
                                </td>
                                <!-- <td class="text-end" role="button" title="Afficher les infras et machines">
                                    <i class="bi bi-building me-2"></i>
                                </td> -->
                            </tr>
                        </tbody>
                    </table>
                </li>
            </ul>
            <div v-else-if="loaded && filteredUsers.length === 0">
                <div class="alert alert-warning">
                    <p class="mb-0"><i class="bi bi-info-circle-fill me-2"></i>Aucun utilisateurs ne correspond à votre recherche.</p>
                </div>
            </div>
            <div v-else>
                <span class="spinner spinner-border spinner-border-sm me-2"></span> {{ (loadMessage) ? loadMessage : "Chargement..."}}
            </div>
        </div>

        <!-- UserAdd Modal -->
        <UserAdd modalId='UserAddModal' 
            :show="showUserAdd"
            :user-info="userInfo"
            :quotas="quotas"
            @close="showUserAdd = false"
            @success="(data) => handleSuccess()"
            @error="(data) => handleError(data)" />

        <!-- Error Modal -->
        <ErrorBox modal-id="AdminErrorBox"
            :show="error" 
            :message="error" 
            @close="error=false" />

        <!-- Success Modal -->
        <ConfirmBox modal-id="SuccessBox"
            :show="showSuccess" 
            :message="successMessage"
            typeOf="info" 
            no="Fermer"
            @close="showSuccess = false" 
            messageOnly="true" />

        <!-- Confirm Trash Modal -->
        <ConfirmBox modal-id="DeleteConfirmBox"
            :show="showConfirm" 
            message="Êtes vous certain de vouloir supprimer ces éléments ?"
            typeOf="question" 
            @close="showConfirm = false" 
            @decline="showConfirm = false" 
            @confirm="deleteUsers()" />

        <!-- Confirm Enable Modal -->
        <ConfirmBox modal-id="EnableConfirmBox"
            :show="showConfirmEnable"
            message="Souhaitez vous activer ou désactiver ces comptes ?"
            typeOf="question"
            yes="Activer"
            no="Désactiver" 
            @close="showConfirmEnable = false" 
            @decline="toggleUsers(false)" 
            @confirm="toggleUsers(true)" />

        <!-- Import Modal -->
        <ImportBox modal-id="ImportUsersBox"
            :show="showImportUsers"
            no-small-modal="true"
            title="Importer des utilisateurs"
            message="Vous pouvez importer une liste d'utilisateur en ajoutant leurs emails dans la zone de saisie ci-dessous. Veuillez séparer chaque email par un saut de ligne."
            @close="showImportUsers = false"
            @import-clicked="(list) => importUsers(list)"/>
        
        <ImportBox modal-id="ImportModoratorsBox"
            :show="showImportModerators"
            no-small-modal="true"
            title="Importer des modérateurs"
            message="Vous pouvez importer une liste de modérateur en ajoutant leurs emails dans la zone de saisie ci-dessous. Veuillez séparer chaque email par un saut de ligne."
            @close="showImportModerators = false"
            @import-clicked="(list) => importModerators(list)"/>

        <!-- Progress Bar -->
        <ProgressBar modal-id="progressBarUser" 
            :show="showProgress"
            :percent="progressPercent"
            :message="progressMessage"
            @close="progressFinished()"
            />

        <!-- Describe User Modal -->
        <DescribeUser modal-id="DescribeUserModal" 
            :show="showDescribeUser"
            :user="currentUser"
            :user-protected="describeUserProtected"
            :group="currentUserGroup"
            :groups="groups"
            :limits="currentUserLimits"
            :quotas="quotas"
            @close="showDescribeUser = false"
            @saved="describeUserSave()" />

    </div>
</template>

<script setup>

    import {onMounted, ref, defineEmits, defineProps, computed} from "vue"
    import axios from "axios";
    
    import UserAdd from "./components/UserAdd.vue";
    import ErrorBox from "./components/generics/ErrorBox.vue";
    import ConfirmBox from "./components/generics/ConfirmBox.vue";
    import ImportBox from "./components/generics/ImportBox.vue";
    import ProgressBar from "./components/generics/ProgressBar.vue";
    import DescribeUser from "./components/DescribeUser.vue";

    let props = defineProps(["userInfo"]);
    let users = ref([]);
    let selectedUsers = ref([]);
    let currentUser = ref(null); 
    let currentUserGroup = ref(null);
    let currentUserLimits = ref(null);
    let quotas = ref([]);
    let groups = ref([]);
    let describeUserProtected = ref(false);

    // For searching
    let searchUser = ref("");
    let filterGroup = ref("0");
    const filteredUsers = computed( () => {
        let usersFiltered = users.value;
        
        // Filter per group first
        if(filterGroup.value !== "0"){
            let membersIds = filterGroup.value.members.map( m => m._id)
            usersFiltered = users.value.filter( u => membersIds.indexOf(u._id) > -1 )
        }
        else{
            usersFiltered = users.value;
        }

        // Filter by searching words
        if(searchUser.value !== ""){
            usersFiltered = usersFiltered.reduce( (previous, current) => {
                let find = false;
                for(let key in current){
                    if( current[key].toString().indexOf(searchUser.value) > -1){
                        find = true;
                    }
                }
                if(find) previous.push(current); 
                return previous;
            }, []);
        }

        return usersFiltered;
    })
    
    // For showing modals
    let showUserAdd = ref(false);
    let showConfirm = ref(false);
    let showConfirmEnable = ref(false);
    let showSuccess = ref(false);
    let successMessage = ref("");
    let showImportUsers = ref(false);
    let showImportModerators = ref(false);
    let showDescribeUser = ref(false);
    
    // For showing progress
    let showProgress  = ref(false);
    let progressMessage = ref("");
    let progressPercent = ref(0);

    // For error and loading
    let error = ref(false);
    let loaded = ref(false);
    let loadMessage = ref(false);

    onMounted( () => {
        loadMessage.value = false;
        load();
    })

    function load() {
        loaded.value = false;
        axios.get(`/api/users?limit=150`)
            .then( answer => {
                loadMessage.value = "Chargement des utilisateurs...";
                users.value = answer.data.sort( (a,b) => a.lastName - b.lastName);
                return axios.get("/api/quotas");
            })
            .then( answer => {
                loadMessage.value = "Chargement des profils...";
                quotas.value = answer.data;
                return axios.get("/api/groups");
            })
            .then( answer => {
                loadMessage.value = "Chargement des groupes...";
                groups.value = answer.data;
                loaded.value = true;
            })
            .catch( answer => {
                alert(answer.data);
            })
    }

    function search(){
        loaded.value = false;
        axios.get("/api/users?limits=150&fields=email:"+searchUser.value)
            .then( answer => {
                users.value = answer.data.sort( (a,b) => a.lastName - b.lastName);
                loaded.value = true;
            })
            .catch( answer => {
                alert(answer.data);
            })
    }

    function handleError(errorMsg){
        error.value = errorMsg;
    }

    function handleSuccess(){
        load();
    }

    async function deleteUsers(){
        showConfirm.value = false;
        showProgress.value = true;
        try{
            let i = 0; //-> to Send axios request
            let f = 0; //-> to follow received answers
            while(i < selectedUsers.value.length){
                let userId = selectedUsers.value[i];
                axios.delete(`/api/users/${userId}`)
                    .then( answer => {
                        progressMessage.value =`Supression d'utilisateurs en cours (${i}/${selectedUsers.value.length})...`;
                        progressPercent.value = ( (f+1) / selectedUsers.value.length ) * 100;
                        if(f===selectedUsers.value.length) progressMessage.value =`Action terminée !`;
                        f++;
                    })
                    .catch( e => {
                        progressMessage.value = `Erreur !`;
                        progressPercent.value = 100;
                        error.value = (e.response.data.message) ? e.response.data.message : e;
                        f=selectedUsers.value.length;
                        i=selectedUsers.value.length;
                    })
                i++;
            }
        }
        catch(e){
            progressPercent.value = 100;
            error.value = (e.response.data.message) ? e.response.data.message : (e.response.data) ? e.response.data : e;
        }
    }

    async function toggleUsers(enable){
        showConfirmEnable.value = false;
        showProgress.value = true;
        for(let i = 0; i < selectedUsers.value.length; i++){
            let userId = selectedUsers.value[i];
            try{
                progressMessage.value =`${(enable) ? "Activation":"Supsension"} en cours (${i+1}/${selectedUsers.value.length})...`;
                await axios.put(`/api/users/${userId}`, {isActive: enable});  
            }
            catch(e){
                progressPercent.value = 100;
                error.value = (e.response.data.message) ? e.response.data.message : e;
            }
            progressPercent.value = ( (i+1) / selectedUsers.value.length ) * 100;
        }
        progressMessage.value =`Action terminée !`;
    }

    /**
     * Didn't use async to be more faster
     */
    async function importUsers(list){
        showImportUsers.value = false;
        showProgress.value = true;
        try{
            let i = 0; //-> to Send axios request
            let f = 0; //-> to follow received answers
            while(i < list.length){
                let email = list[i];
                axios.post("/api/users", {email: email, authLevel: 2})
                    .then( answer => {
                        progressMessage.value = `Importation en cours (${f+1}/${list.length})`;
                        progressPercent.value = ( (f+1) / list.length ) * 100;
                        if(f===list.length) progressMessage.value =`Action terminée !`;
                        f++;
                    })
                    .catch( e => {
                        progressMessage.value = `Erreur !`;
                        progressPercent.value = 100;
                        error.value = (e.response.data.message) ? e.response.data.message : e;
                        f=list.length;
                        i=list.length;
                    })
                i++;
            }
        }
        catch(e){
            progressPercent.value = 100;
            error.value = (e.response.data.message) ? e.response.data.message : e;
        }
    }

    async function importModerators(list){
        showImportModerators.value = false;
        showProgress.value = true;
        try{
            let i = 0; //-> to Send axios request
            let f = 0; //-> to follow received answers
            while(i < list.length){
                let email = list[i];
                axios.post("/api/users", {email: email, authLevel: 1})
                    .then( answer => {
                        progressMessage.value = `Importation en cours (${f+1}/${list.length})`;
                        progressPercent.value = ( (f+1) / list.length ) * 100;
                        if(f===list.length) progressMessage.value =`Action terminée !`;
                        f++;
                    })
                    .catch( e => {
                        progressMessage.value = `Erreur !`;
                        progressPercent.value = 100;
                        error.value = (e.response.data.message) ? e.response.data.message : e;
                        f=list.length;
                        i=list.length;
                    })
                i++;
            }
        }
        catch(e){
            progressPercent.value = 100;
            error.value = (e.response.data.message) ? e.response.data.message : e;
        }
    }

    async function describeUserClicked(user){
        loadMessage.value = "Chargement des infos utilisateur...";
        loaded.value = false;
        currentUser.value = user;
        if(currentUser.value.authLevel === 0){
            describeUserProtected.value = true;
        }
        else{
            describeUserProtected.value = false;
        }
        currentUserGroup.value = (await axios.get(`/api/users/${user._id}/group`)).data;
        currentUserLimits.value = (await axios.get(`/api/users/${user._id}/limits`)).data;
        loaded.value = true;
        showDescribeUser.value = true;
    }

    async function describeUserSave(){
        showDescribeUser.value = false;
        load();
    }
    
    async function progressFinished(){
        showProgress.value = false;
        progressMessage.value = "";
        progressPercent.value = 0;
        load();
    }

</script>